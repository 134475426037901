.wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	cursor: pointer;
	> span {
		font-size: 20px;
		font-weight: bold;
		color: var(--blue-grey);
		font-weight: normal;
		display: block;
		margin-bottom: 5px;
	}
}
