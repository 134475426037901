.wrapper {
	padding: 15px 15px 15px 25px;
	border: 1px solid var(--gray-10);
	backdrop-filter: blur(20px);
	border-radius: 10px;
	color: var(--text);
	transition: 300ms ease;
	font-family: var(--font-title), sans-serif;
	display: block;
	input {
		// position: absolute;
		// left: -999vw;
	}
}
.label {
	transition: 200ms ease-out;
	color: var(--gray);
	margin-bottom: 8px;
}
