.wrapper {
	font-weight: bold;
	font-size: 40px;
	@media (max-width: 1200px) {
		font-size: 30px;
	}
	@media (max-width: 992px) {
		font-size: 24px;
	}
}
