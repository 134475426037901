.wrapper {
	margin-bottom: 20px;
	.label {
		transition: 200ms ease-out;
		color: var(--gray);
		margin-bottom: 8px;
	}
	input {
		box-sizing: border-box;
		padding: 15px 15px 15px 25px;
		font-size: 16px;
		color: var(--text);
		transition: 300ms ease;
		font-family: var(--font-title), sans-serif;
		outline: none;
		position: relative;
		background-color: transparent;
		transition: 200ms ease-out;
		width: 100%;
		backdrop-filter: blur(20px);
		border: 1px solid var(--gray-10);
		border-radius: 10px;
		margin-bottom: 10px;
	}
	.error-text {
		color: var(--accent);
	}
	.black {
		color: var(--black);
	}
	&.isSearch {
		margin-bottom: 0;
		input {
			padding: 12px 20px;
			border-radius: 0px;
			background-color: transparent;
			border: none;
			border-bottom: 1px solid var(--swan);
			font-size: 18px;
			width: 100%;
			color: var(--white);
			&:focus {
				outline: none;
				border-bottom-color: var(--primary);
			}
		}
	}
}
