:root {
  --gray-100: #111310;
  --gray-80: #1b1e1a;
  --gray-70: #1d201d;
  --gray-60: #323630;
  --gray-40: #545850;
  --gray-20: #767a72;
  --gray-10: #989b94;
  --gray: #eee;
  /**/
  --leaf: #72b431;
  --primary: #72b431;
  --primary-80: #588a26;
  --primary-opacity: rgb(114, 180, 49, 0.5);
  --accent: #eb3737;
  --accent-opacity: rgb(235, 55, 55, 0.5);
  --secondary: #3c3934;
  --foreground: var(--gray-80);
  --background: var(--gray-100);
  --border: var(--gray-40);
  --border-opacity: rgb(84, 88, 80, 0.5);
  --text: var(--gray);
  /**/
  --orangey-red: #eb3737;
  --black: #000;
  --blue-grey: #8792ac;
  --bright-cyan: #42c3f8;
  --bright-cyan-opacity: rgb(66, 195, 248, 0.5);
  --brown-grey: #a0a0a0;
  --dark: #191b20;
  --dark-opacity: #a3a4a6;
  --grass: #61a320;
  --gray-sub: #636b7e;
  --gark-gray: #000;
  --green-apple: #80d705;
  --green: #72b431;
  --mango: #ffb72c;
  --orangey-red: #000;
  --white: #fff;
  --grey: #dcdcdc;
  --battleship-grey: #636b7e;
  --light-grey: #e5e5e5;
  --silver: #eee;
  --swan: #b5b5b5;
  --red: #ea3b4a;
  --color-gray-60: #c9cedc;
  --transition: ease 0.15s;
  --color-gray-70: #8e97af;
  --color-gray-50: #e1e4eb;
  --color-gray-20: #f9fafc;
}
