@use "./../../styles/mixins";

.wrapper {
  border-right: 1px solid var(--border);
  background-color: var(--gray-70);
  .menuItem {
    width: 100%;
    transition: 200ms ease-out;
    cursor: pointer !important;
    &.active,
    &:hover {
      .icon {
        @include mixins.svgColor(var(--primary));
      }
      * {
        color: var(--primary);
      }
    }
    &:active {
      transform: scale(0.97);
      * {
        color: var(--primary);
      }
    }
    .icon {
      margin-right: 10px;
    }
  }
}
