@mixin font-face($name, $file, $weight: 400, $style: normal) {
	@font-face {
		font-family: '#{$name}';
		src: local('#{$file}'), url('../assets/fonts/#{$file}.woff2') format('woff2'),
			url('../assets/fonts/#{$file}.woff') format('woff');
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}

@mixin bg($size: 'contain', $position: 'center') {
	background-size: #{$size};
	background-position: #{$position};
	background-repeat: no-repeat;
}

@mixin btn_anim($scaleMax: 1.05, $scaleMin: 0.95) {
	transform-origin: center center;
	transition: all ease-out 240ms;

	&:hover {
		transform: scale(#{$scaleMax});
	}

	&:focus {
		outline: transparent;
	}

	&:focus-visible {
		transform: scale(#{$scaleMax}) translateY(-5%);
	}

	&:active {
		transform: scale(#{$scaleMin});
	}
}

@mixin no-btn($display: 'inline-block') {
	padding: 0;
	margin: 0;
	border: 0;
	background-color: transparent;
	border-radius: 0;
	cursor: pointer;
	appearance: none;
	display: #{$display};
}

@mixin transit($tr: 0.5) {
	transition: all #{$tr}s;
}




@mixin svgColor($color, $exclude: none) {
	*[fill]:not([fill='none']):not([fill='#{$exclude}']) {
		fill: $color;
	}
	*[stroke]:not([stroke='none']):not([stroke='#{$exclude}']) {
		stroke: $color;
	}
}

@mixin color {
	&-white,
	&-WHITE {
		@content (var(--white));
	}
	&-red,
	&-RED {
		@content (var(--orangey-red));
	}
	&-green,
	&-GREEN {
		@content (var(--green));
	}
	&-leaf,
	&-LEAF {
		@content (var(--leaf));
	}
	&-primary,
	&-PRIMARY {
		@content (var(--primary));
	}
	&-blue,
	&-BLUE {
		@content (var(--bright-cyan));
	}
	&-yellow,
	&-YELLOW {
		@content (var(--mango));
	}
	&-gray,
	&-GRAY {
		@content (var(--blue-grey));
	}
	&-light_gray,
	&-LIGHT_GRAY {
		@content (var(--silver));
	}
	&-dark_gray,
	&-DARK_GRAY {
		@content (var(--dark-opacity));
	}
}
