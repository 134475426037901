.button {
	appearance: none;
	outline: none;

	color: #ffffff;
	cursor: pointer;

	font-family: var(--font-title), sans-serif;
	font-size: 16px;

	transition: ease 0.15s;

	--button-color: var(--primary);
	--button-hover-color: var(--primary-80);
	--button-focus-color: var(--primary-80);
	--button-loading-color: var(--primary);
	--button-disabled-color: #e1e4eb;

	--button-text-color: #ffffff;
	--button-hover-text-color: #ffffff;
	--button-focus-text-color: #ffffff;
	--button-disabled-text-color: #c9cedc;

	--button-loading-color-start: rgba(255, 255, 255, 1);
	--button-loading-color-end: rgba(255, 255, 255, 0.2);

	&.color {
		&-gray {
			// --button-color: #f0f3f7;
			--button-color: transparent;
			--button-hover-color: #e1e4eb;
			--button-focus-color: #e1e4eb;
			--button-loading-color: #e1e4eb;
			// --button-disabled-color: #f0f3f7;
			--button-disabled-color: transparent;

			--button-text-color: #8e97af;
			--button-hover-text-color: #5c6585;
			--button-focus-text-color: #5c6585;
			--button-disabled-text-color: #c9cedc;

			--button-loading-color-start: rgba(142, 151, 175, 1);
			--button-loading-color-end: rgba(142, 151, 175, 0.2);
		}
		&-red {
			--button-color: #f1465a;
			--button-hover-color: #ff5f71;
			--button-focus-color: #ff5f71;
			--button-loading-color: #ff5f71;
			--button-disabled-color: #e1e4eb;

			--button-text-color: #ffffff;
			--button-hover-text-color: #ffffff;
			--button-focus-text-color: #ffffff;
			--button-disabled-text-color: #c9cedc;

			--button-loading-color-start: rgba(255, 255, 255, 1);
			--button-loading-color-end: rgba(255, 255, 255, 0.2);
		}
		&-darkGray {
			--button-color: #e1e4eb;
			--button-hover-color: #c9cedc;
			--button-focus-color: #c9cedc;
			--button-loading-color: #c9cedc;
			--button-disabled-color: #e1e4eb;

			--button-text-color: #5c6585;
			--button-hover-text-color: #5c6585;
			--button-focus-text-color: #5c6585;
			--button-disabled-text-color: #c9cedc;

			--button-loading-color-start: rgba(92, 101, 133, 1);
			--button-loading-color-end: rgba(92, 101, 133, 0.2);
		}
	}

	&.isLink {
		padding: 0 !important;

		--button-color: transparent;
		--button-hover-color: transparent;
		--button-focus-color: transparent;
		--button-loading-color: transparent;
		--button-disabled-color: transparent;

		--button-text-color: #3dbc87;
		--button-hover-text-color: #68cba2;
		--button-focus-text-color: #68cba2;
		--button-disabled-text-color: #c9cedc;

		--button-loading-color-start: transparent;
		--button-loading-color-end: transparent;
	}

	background: var(--button-color);
	border: 2px solid var(--button-color);
	color: var(--button-text-color);

	&:hover {
		background: var(--button-hover-color);
		border: 2px solid var(--button-hover-color);
		color: var(--button-hover-text-color);
	}

	&:focus {
		background: var(--button-focus-color);
		border: 2px solid var(--button-focus-color);
		color: var(--button-focus-text-color);
	}

	&.loading {
		background: var(--button-loading-color);
		border: 2px solid var(--button-loading-color);
		color: transparent;
	}
	&.disabled {
		background: var(--button-disabled-color);
		border: 2px solid var(--button-disabled-color);
		color: var(--button-disabled-text-color);
	}

	&.size {
		&-big {
			padding: 11px 20px;
			border-radius: 12px;
		}
		&-medium {
			padding: 6px 16px;
			border-radius: 8px;
		}
		&-small {
			padding: 4px 12px;
			border-radius: 8px;
		}
	}

	@keyframes loading {
		from {
			background: var(--button-loading-color-start);
		}
		to {
			background: var(--button-loading-color-end);
		}
	}

	&.loading {
		color: transparent !important;
		position: relative;
		span {
			position: absolute;
			display: block;
			width: 4px;
			height: 4px;
			background: rgba(255, 255, 255, 1);
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			border-radius: 50%;
			animation: linear loading infinite 0.9s 0.3s;
			&::after,
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 4px;
				height: 4px;
				background: #fff;
				border-radius: 50%;
			}
			&::after {
				left: -8px;
				animation: linear loading infinite 0.9s 0s;
			}
			&::before {
				right: -8px;
				animation: linear loading infinite 0.9s 0.6s;
			}
		}
	}

	&.witoutBackround {
		background: transparent !important;
	}

	&.fullWidth {
		width: 100%;
	}
}
