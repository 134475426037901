@use './../../styles/mixins';

.wrapper {
	&.size {
		&-24 {
			> svg {
				width: 24px;
				height: 24px;
			}
		}
		&-28 {
			> svg {
				width: 28px;
				height: 28px;
			}
		}
		&-32 {
			> svg {
				width: 32px;
				height: 32px;
			}
		}
		&-44 {
			> svg {
				width: 44px;
				height: 44px;
			}
		}
		&-62 {
			> svg {
				width: 62px;
				height: 62px;
			}
		}
	}
	&.color {
		@include mixins.color using($color) {
			@include mixins.svgColor($color);
		}
	}
	&.direction {
		&-left {
			> svg {
				transform: rotate(90deg);
			}
		}
		&-right {
			> svg {
				transform: rotate(270deg);
			}
		}
		&-down {
			> svg {
				transform: rotate(0deg);
			}
		}
		&-up {
			> svg {
				transform: rotate(180deg);
			}
		}
	}
}
