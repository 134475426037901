.wrapper {
	display: grid;
	grid-template-columns: 220px 1fr;
	grid-template-rows: 50px 1fr;
	min-height: 100vh;
	background-color: var(--background);
	.inner {
		padding: 20px 20px 20px 50px;
		max-width: 1024px;
		margin: 0 auto;
		width: 100%;
	}
	.header {
		grid-column: 1/3;
		border-bottom: 1px solid var(--border);
		background-color: var(--gray-80);
	}
}
